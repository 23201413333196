$enable-rounded: false;
$enable-transitions: false;
$enable-shadows: false;
$enable-gradients: false;
$enable-validation-icons: true;

// Прокрутка уменьшает реальный размер видимого окна на 10px, но не учитывается в подсчётах параметров размера окна
$grid-breakpoints: (
                   //default
        xs: 0,
        sm: 760px - 10px, //576px,
        md: 950px - 10px, //768px,
        lg: 1060px - 10px, //992px,
        xl: 1360px - 10px, //1200px,
        xxl: 1660px - 10px //1400px
);
$container-max-widths: (
                   //default //flex
        sm: 760px, //540px, //640px,  // Начало сужения одной колонки
        md: 950px, //720px, //768px,  // Минимальный размер двух колонок с наездом, переход в одну колонку
        lg: 1060px, //960px, //1024px,  // Минимальный размер двух колонок без наездов
        xl: 1360px, //1140px, //1280px,  // Две колонки
        xxl: 1660px //1320px //1536px  // Три колонки
);

$font-family-sans-serif: Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;
$input-btn-font-family: $font-family-base;
$headings-font-family: $font-family-base;
//$headings-line-height: 1.1;
$display-font-family: $headings-font-family;
$display-font-weight: 300;
$font-family-monospace: Iosevka,SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
$font-family-code: $font-family-monospace;

$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.25;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.75;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$blue: #0055a2;
$green: #00a969;
$red: #ff1634;
$yellow: #fabe00;
$cyan: #10aabd;
$indigo: #6561db;
$orange: #fb773f;
$pink: #f54d7d;
$purple: #9350ac;
$teal: #00ab9f;

$body-color: #3c4a57;
$text-muted: #6c757d;
$headings-color: $blue;
$primary: $blue;
$secondary: #556987;
$success: $green;
$info: $indigo;
$warning: $yellow;
$danger: $red;
$light: #f1f1f1;
$dark: #20262b;
$dark-light: #404F65;

$link-color: #104584;
$link-decoration: underline;
$link-hover-decoration: none;
